// Import necessary dependencies
import { defineStore } from "pinia";
import axios from 'axios';

import globalComposables from "@commons/composables/global";
const { getCookieValue } = globalComposables()

import { useAuthStore } from "@commons/store";

// Define the 'vault' store module
export const useVaultStore = defineStore('vault', {
  persist: true,
  
  state: () => {
    return {
      wallets: [],
      withdrawals: [],
      deposits: [],
      invoices: [],
      transactions: [],
      balances: [],
      configurations: [],
      committees: [],
      committeesRequests: [],
    };
  },

  getters: {
    getWalletList(state) {
      return state.wallets;
    },
    getDepositAddress(state) {
      return state.deposits;
    },
    getWithdrawalsList(state) {
      return state.withdrawals;
    },
    getInvoicesList(state) {
      return state.invoices;
    },
    getTransactionsList(state) {
      return state.transactions;
    },
    getBalancesList(state) {
      return state.balances;
    },
    getConfigurationsList(state) {
      return state.configurations;
    },
    getCommitteesList(state) {
      return state.committees;
    },
    getCommitteesRequestsList(state) {
      return state.committeesRequests;
    },
  },

  actions: {
    getAllWallets() {
      const storeAuth = useAuthStore()
      if(storeAuth.isLoggedIn && storeAuth.getAccessToken) {
        return new Promise((resolve, reject) => {
          axios.get(
            `${process.env.VUE_APP_API_URL}vaults/wallets?userId=${storeAuth.getUserId}`, {
              withCredentials: true,
              headers: {
                  "x-csrf-token": getCookieValue("X-CSRF-TOKEN") || storeAuth.getCSRFToken,
                  "X-ACCESS-TOKEN": storeAuth.getAccessToken
              },
            }
          )
          .then((res) => {
            if (res && res.data) {
              this.setAccounts(res.data);
              resolve(res);
            }
          })
          .catch((err) => {
              console.log(err);
              reject(err);
          });
        });
      }
    },
    getDeposits(wallet) {
      const storeAuth = useAuthStore()
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}vaults/wallets/${wallet}/deposits`, {}, {
              withCredentials: true,
              headers: {
                  "x-csrf-token": getCookieValue("X-CSRF-TOKEN") || storeAuth.getCSRFToken,
                  "X-ACCESS-TOKEN": storeAuth.getAccessToken
              },
            }
          )
          .then((res) => {
            if (res && res.data) {
              this.deposits[wallet] = res.data
              resolve(res);
            }
          })
          .catch((err) => {
              console.log(err);
              reject(err);
          });
        });
    },
    getWithdrawals(wallet) {
      const storeAuth = useAuthStore()
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_API_URL}vaults/wallets/${wallet}/withdrawals`, {
              withCredentials: true,
              headers: {
                  "x-csrf-token": getCookieValue("X-CSRF-TOKEN") || storeAuth.getCSRFToken,
                  "X-ACCESS-TOKEN": storeAuth.getAccessToken
              },
            }
          )
          .then((res) => {
            if (res && res.data) {
              this.setWithdrawals(res.data);
              resolve(res);
            }
          })
          .catch((err) => {
              console.log(err);
              reject(err);
          });
        });
    },
    getInvoices(wallet) {
      const storeAuth = useAuthStore()
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_API_URL}vaults/wallets/${wallet}/invoices`, {
              withCredentials: true,
              headers: {
                  "x-csrf-token": getCookieValue("X-CSRF-TOKEN") || storeAuth.getCSRFToken,
                  "X-ACCESS-TOKEN": storeAuth.getAccessToken
              },
            }
          )
          .then((res) => {
            if (res && res.data) {
              this.setInvoices({ wallet: wallet, data: res.data });
              resolve(res);
            }
          })
          .catch((err) => {
              console.log(err);
              reject(err);
          });
        });
    },
    getBalances(wallet) {
      const storeAuth = useAuthStore()
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_API_URL}vaults/wallets/${wallet}/balances`, {
              withCredentials: true,
              headers: {
                  "x-csrf-token": getCookieValue("X-CSRF-TOKEN") || storeAuth.getCSRFToken,
                  "X-ACCESS-TOKEN": storeAuth.getAccessToken
              },
            }
          )
          .then((res) => {
            if (res && res.data) {
              this.setBalances({ wallet: wallet, data: res.data });
              resolve(res);
            }
          })
          .catch((err) => {
              console.log(err);
              reject(err);
          });
        });
    },
    getTransactions(wallet) {
      const storeAuth = useAuthStore()
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_API_URL}vaults/wallets/${wallet}/transactions`, {
              withCredentials: true,
              headers: {
                  "x-csrf-token": getCookieValue("X-CSRF-TOKEN") || storeAuth.getCSRFToken,
                  "X-ACCESS-TOKEN": storeAuth.getAccessToken
              },
            }
          )
          .then((res) => {
            if (res && res.data) {
              this.setTransactions({ wallet: wallet, data: res.data });
              resolve(res);
            }
          })
          .catch((err) => {
              console.log(err);
              reject(err);
          });
        });
    },
    getConfigurations(wallet) {
      const storeAuth = useAuthStore()
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_API_URL}vaults/wallets/${wallet}/configurations`, {
              withCredentials: true,
              headers: {
                  "x-csrf-token": getCookieValue("X-CSRF-TOKEN") || storeAuth.getCSRFToken,
                  "X-ACCESS-TOKEN": storeAuth.getAccessToken
              },
            }
          )
          .then((res) => {
            if (res && res.data) {
              this.setConfigurations(res.data);
              resolve(res);
            }
          })
          .catch((err) => {
              console.log(err);
              reject(err);
          });
        });
    },
    getCommittees(wallet) {
      const storeAuth = useAuthStore()
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_API_URL}vaults/wallets/${wallet}/committees`, {
              withCredentials: true,
              headers: {
                  "x-csrf-token": getCookieValue("X-CSRF-TOKEN") || storeAuth.getCSRFToken,
                  "X-ACCESS-TOKEN": storeAuth.getAccessToken
              },
            }
          )
          .then((res) => {
            if (res && res.data) {
              this.setCommittees(res.data);
              resolve(res);
            }
          })
          .catch((err) => {
              console.log(err);
              reject(err);
          });
        });
    },
    getCommitteesRequests(wallet) {
      const storeAuth = useAuthStore()
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${process.env.VUE_APP_API_URL}vaults/wallets/${wallet}/committees/requests`, {
              withCredentials: true,
              headers: {
                  "x-csrf-token": getCookieValue("X-CSRF-TOKEN") || storeAuth.getCSRFToken,
                  "X-ACCESS-TOKEN": storeAuth.getAccessToken
              },
            }
          )
          .then((res) => {
            if (res && res.data) {
              this.setCommitteesRequests(res.data);
              resolve(res);
            }
          })
          .catch((err) => {
              console.log(err);
              reject(err);
          });
        });
    },

    createWithdrawal(payload) {
      const storeAuth = useAuthStore()
      if(payload.wallet) {
        return new Promise((resolve, reject) => {
          axios
            .post(
              `${process.env.VUE_APP_API_URL}vaults/wallets?walletId=${payload.wallet}`, payload.data,
              {
                withCredentials: true,
                headers: {
                    "x-csrf-token": getCookieValue("X-CSRF-TOKEN") || storeAuth.getCSRFToken,
                    "X-ACCESS-TOKEN": storeAuth.getAccessToken
                },
              }
            )
            .then((res) => {
              if (res && res.data) {
                  this.addWithdrawal({ wallet: payload.wallet, data: res.data });
                  resolve(res);
              } else {
                  resolve(false);
              }
            }).catch((error) => {
                console.log("Error fetching data:", error);
                reject(error)
            });
          });
        } else {
          return;
        }
    },
    
    
    addNewAccountVault(payload) {
        const storeAuth = useAuthStore()
        return new Promise((resolve, reject) => {
          axios.post(
            `${process.env.VUE_APP_API_URL}vaults/wallets`,
              payload, {
                  withCredentials: true,
                  headers: {
                      "x-csrf-token": getCookieValue("X-CSRF-TOKEN") ? getCookieValue("X-CSRF-TOKEN") : storeAuth.getCSRFToken,
                      "X-ACCESS-TOKEN": storeAuth.getAccessToken,
                  },
              }
          )
          .then((res) => {
              if (res && res.data) {
                  this.addAccount({ type: (payload.networkIdentifier).toLowerCase(), data: res.data });
                  resolve(res);
              } else {
                  resolve(false);
              }
          }).catch((error) => {
              console.log("Error fetching data:", error);
              reject(error)
          });
        });
      },
      
      

    setAccounts(payload) {
      console.log(payload)
      // Use reduce to transform the array of wallets into an object with currencies as keys
      if(Array.isArray(payload) && payload) {
        this.wallets = payload.reduce((acc, wallet) => {
          const { networkIdentifier, ...rest } = wallet;
          if (!acc[networkIdentifier]) {
            acc[networkIdentifier] = []; // Initialize an array if it doesn't exist
          }
          acc[networkIdentifier].push({ networkIdentifier, ...rest }); // Push the wallet object into the array
          return acc;
        }, {});
      }
    },
    setInvoices(payload) {
      // Use reduce to transform the array of wallets into an object with currencies as keys
      if (payload?.walletId) {
        this.invoices[payload.walletId].push(payload.data);
      }
    },
    setWithdrawals(payload) {
      // Use reduce to transform the array of wallets into an object with currencies as keys
      if (payload?.walletId) {
        this.withdrawals({ wallet: payload.walletId, data: payload });
      }
    },
    setTransactions(payload) {
      // Use reduce to transform the array of wallets into an object with currencies as keys
      if (payload?.walletId) {
        this.transactions[payload.walletId].push(payload.data);
      }
    },
    setBalances(payload) {
      console.log("set balances")
      console.log(payload)

      // Check if payload.wallet is defined and initialize the array if it doesn't exist
      if (payload?.wallet) {
        if (!this.balances[payload.wallet]) {
          // Initialize as an empty array if it doesn't exist
          this.balances[payload.wallet] = [];
        }

        // Check for existing balance entry
        const existingIndex = this.balances[payload.wallet].findIndex(balanceEntry =>
          balanceEntry.currency === payload.data.currency &&
          balanceEntry.networkIdentifier === payload.data.networkIdentifier
        );

        if (existingIndex > -1) {
          // Update the existing balance and timestamp
          this.balances[payload.wallet][existingIndex].balance = payload.data.balance;
          this.balances[payload.wallet][existingIndex].timestamp = payload.data.timestamp;
        } else {
          // Push new entry if no matching entry is found
          this.balances[payload.wallet].push(payload.data);
        }

        console.log(this.balances);
      }
      
    },
    setConfigurations(payload) {
      // Use reduce to transform the array of wallets into an object with currencies as keys
      if (payload?.walletId) {
        this.configurations[payload.walletId].push(payload.data);
      }
    },
    setCommittees(payload) {
      // Use reduce to transform the array of wallets into an object with currencies as keys
      if (payload?.walletId && this.committees[payload.walletId] && this.committees[payload.walletId].length > 0) {
        this.committees[payload.walletId].push(payload.data);
      }
    },
    setCommitteesRequests(payload) {
      // Use reduce to transform the array of wallets into an object with currencies as keys
      if (payload?.walletId) {
        this.committeesRequests[payload.walletId].push(payload.data);
      }
    },
    addAccount(payload) {
      console.log('add account')
      console.log(this.wallets)
      console.log(payload)
      if (payload?.type) {
        this.wallets[payload.type.toLowerCase()] = []
        this.wallets[payload.type.toLowerCase()].push(payload.data);
      }
    },
    addWithdrawal(payload) {
      if (payload?.walletId) {
        this.withdrawals[payload.walletId].push(payload.data);
      }
    },
    deleteAccount(payload) {
      /* this.wallets[method.toLowerCase()].splice(
          this.wallets[method.toLowerCase()].findIndex((wallet) => wallet.id === payload.id),
          1
        ); */
     console.log(payload)
     return;
    },
  },
});
