// Import necessary dependencies
import { defineStore } from "pinia";
import axios from 'axios';

// Import global composable
import globalComposables from '@commons/composables/global';
const { getCookieValue } = globalComposables();

import { useAuthStore } from "@commons/store";

// Define the 'connection' store module
export const useConnectionStore = defineStore('connections', {
  persist: true,
  state: () => {
    return {
      connections: [],
    };
  },

  getters: {
    getConnectionList(state) {
      return state.connections;
    },
  },

  actions: {
    async getAllConnectionDetails() {
      const storeAuth = useAuthStore()
      const response = await axios.get(process.env.VUE_APP_API_ORDER_URL + 'contracts', {
        withCredentials: true,
        headers: {
          'x-csrf-token': getCookieValue('X-CSRF-TOKEN') || storeAuth.getCSRFToken,
        },
      });

      if (response && response.data && response.status === 200) {
        this.setConnection(response.data.results);
        return true;
      }

      return false;
    },

    async updateConnection(payload) {
      const storeAuth = useAuthStore()
      const response = await axios.patch(
        process.env.VUE_APP_API_ORDER_URL + 'contracts/' + payload.id,
        payload.data,
        {
          withCredentials: true,
          headers: {
            'x-csrf-token': getCookieValue('X-CSRF-TOKEN') || storeAuth.getCSRFToken,
          },
        }
      );

      if (response && response.data && response.status === 200) {
        const index = this.connections.findIndex((conn) => conn.id === response.id);
        if (index !== -1) {
          this.connections[index] = response.data;
        }
        return true;
      }

      return false;
    },

    setConnection(payload) {
      for (let i = 0; i < payload.length; i++) {
        const index = this.connections.findIndex((conn) => conn.id === payload[i].id);
        if (index > -1) {
          this.connections[index] = payload[i];
        } else {
          this.connections.push(payload[i]);
        }
      }
    },
    addConnection(payload) {
      this.connections.push(payload);
    },
    deleteConnection(payload) {
      const index = this.connections.findIndex((conn) => conn.id === payload.id);
      if (index !== -1) {
        this.connections.splice(index, 1);
      }
    },
    deleteConnectionsOfDetail(payload) {
      const connectionsToDelete = this.connections.filter(
        (conn) => conn.incomingPaymentDetailId === payload.id || conn.outgoingPaymentDetailId === payload.id
      );
      for (let i = 0; i < connectionsToDelete.length; i++) {
        const index = this.connections.findIndex((conn) => conn.incomingPaymentDetailId === payload[i].id);
        if (index !== -1) {
          this.connections.splice(index, 1);
        }
        const outgoingIndex = this.connections.findIndex((conn) => conn.outgoingPaymentDetailId === payload[i].id);
        if (outgoingIndex !== -1) {
          this.connections.splice(outgoingIndex, 1);
        }
      }
    },
  },
});
