// Import necessary dependencies
import { defineStore } from "pinia";
import axios from 'axios';

import { currencyFromNetwork, findServiceUrl } from "@commons/components/global.js";
import globalComposables from "@commons/composables/global";
const { getCookieValue } = globalComposables()

import { useAuthStore } from "@commons/store";

import { networks } from "@commons/components/data.js";

// Define the 'wallet' store module
export const useWalletStore = defineStore('wallet', {
  persist: true,
  
  state: () => {
    return {
      wallets: [],
    };
  },

  getters: {
    getWalletList(state) {
      return state.wallets;
    },
  },

  actions: {  
    getAllWalletDetails(networkId) {
      console.log(networkId)
      console.log(findServiceUrl(networkId))
      const storeAuth = useAuthStore()

      if(!currencyFromNetwork(networkId)) { 
        return Promise.resolve(null); 
      }
        
      return new Promise((resolve, reject) => {
        axios.get(findServiceUrl(networkId) + "/" + "details/" + currencyFromNetwork(networkId).toUpperCase(), {
          withCredentials: true,
          headers: {
              "x-csrf-token": getCookieValue("X-CSRF-TOKEN") || storeAuth.getCSRFToken,
          },
        }).then((res) => {
            if (res && res.data && res.status === 200) {
              this.setAccounts({ type: networkId.toLowerCase(), data: res.data });
              resolve(res);
            }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
      });
    },
    
    
    addNewAccount(payload) {
        const storeAuth = useAuthStore()
        return new Promise((resolve, reject) => {
          if (method && currencyFromNetwork(payload.networkId)) {
            axios.post(
              findServiceUrl(networkId) + "/" + "details/" + currencyFromNetwork(payload.networkId).toUpperCase(),
                payload.data, {
                    withCredentials: true,
                    headers: {
                        "x-csrf-token": getCookieValue("X-CSRF-TOKEN") ? getCookieValue("X-CSRF-TOKEN") : storeAuth.getCSRFToken,
                    },
                }
            )
            .then((res) => {
                if (res && res.data && res.status === 201) {
                    this.addAccount({ type: (payload.networkId).toLowerCase(), data: res.data });
                    resolve(res);
                } else {
                    resolve(false);
                }
            }).catch((error) => {
                console.log("Error fetching data:", error);
                reject(error)
            });
          } else {
            resolve(false)
          }
        });
      },
      
      

    setAccounts(payload) {
      this.wallets[payload.type.toLowerCase()] = payload.data;
    },
    addAccount(payload) {
      if (payload.type) {
        this.wallets[payload.type.toLowerCase()].push(payload.data);
      }
    },
    deleteAccount(payload) {
      const network = payload.network
      if (network && this.wallets[network.toLowerCase()]) {
        this.wallets[network.toLowerCase()].splice(
          this.wallets[network.toLowerCase()].findIndex((wallet) => wallet.id === payload.id),
          1
        );
      }
    },
  },
});
