import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import { useAuthStore } from "@commons/store"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/maintainance.vue"), // home.vue
  },
  {
    path: "/error/:section?",
    name: "error",
    component: () => import("@/views/error.vue"),
  },
  {
    path: "/api",
    name: "api",
    component: () => import("@/views/api.vue"),
  },
  {
    path: "/sign-up/:mode?",
    name: "sign-up",
    component: () => import("@/views/signup.vue"),
    meta: {
      requiresGuest: true
    },
  },
  {
    path: "/login/:mode?",
    name: "login",
    component: () => import("@/views/login.vue"),
    meta: {
      requiresGuest: true
    },
  },
  {
    path: "/forgot-password/:email?/:code?",
    name: "forgot-password",
    component: () => import("@/views/forgot-password.vue"),
    meta: {
      requiresGuest: true
    },
  },
  {
    path: "/activate/:email?/:code?/:web3?",
    name: "activate",
    component: () => import("@/views/activate.vue"),
    meta: {
      requiresGuest: true
    },
  },
  {
    path: "/activate-resend",
    name: "activate-resend",
    component: () => import("@/views/activate-resend.vue"),
    meta: {
      requiresGuest: true
    },
  },
  {
    path: "/approve/:email?",
    name: "approve",
    component: () => import("@/views/approve.vue"),
    meta: {
      requiresGuest: true
    },
  },
  {
    path: "/verify",
    name: "verification",
    component: () => import("@/views/user/verification.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/account/:section/:sub?",
    name: "account",
    component: () => import("@/views/user/account.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/info/:section?/:sub?",
    name: "blog",
    component: () => import("@/views/blog.vue"),
  },
  {
    path: "/terms/:section?",
    name: "terms",
    component: () => import("@/views/terms.vue"),
  },
  {
    path: "/support",
    name: "support",
    component: () => import("@/views/support.vue"),
  },
  {
    path: "/levels",
    name: "levels",
    component: () => import("@/views/levels.vue"),
  },
  { 
    path: "/:pathMatch(.*)*",
    name: "error",
    component: () => import("@/views/error.vue"), 
  },
];

const router = createRouter({
  history: createWebHistory(location.pathname.substr(0,3) === "/de" ? process.env.BASE_URL + "de/" : (location.pathname.substr(0,3) === "/lt" ? process.env.BASE_URL + "lt/" : (location.pathname.substr(0,3) === "/pl" ? process.env.BASE_URL + "pl/" : (location.pathname.substr(0,3) === "/it" ? process.env.BASE_URL + "it/" :  (location.pathname.substr(0,3) === "/es" ? process.env.BASE_URL + "es/" :  (location.pathname.substr(0,3) === "/fr" ? process.env.BASE_URL + "fr/" : process.env.BASE_URL)))))),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to) => {
  const storeAuth = useAuthStore();
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);
  const isLoggedin = (storeAuth as any).logged;
  if (requiresAuth && !isLoggedin) {
    return { name: 'login' }
  } else if (requiresGuest && isLoggedin) {
    return { name: 'home' }
  }
});

export default router;
