<template>
  <div>
    <notifications />
    <router-view class="min-h-screen bg-dayBg dark:bg-nightBg text-gray-600 dark:text-[#9CA3AF] font-archivo break-words" />
  </div>
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent } from "vue";
const notifications = defineAsyncComponent({
  loader: () => import("@/components/notifications.vue" /* webpackChunkName: "notifications" */),
});

export default defineComponent({
  name: "AppComponent",
  components: {
    notifications,
  },
  mounted() {
    if(this.$route.query && this.$route.query.ref) {
      localStorage.setItem('ref', this.$route.query.ref);
    }
  }
});
</script>

<style>
*,*:focus,*:hover{
    outline:none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active,
input:autofill,
input:-internal-autofill-selected {
  background-color:transparent !important;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: gray !important;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@keyframes autofill {
    0%,100% {
        background: transparent;
    }
}
@-webkit-keyframes autofill {
    0%,100% {
        background: transparent;
    }
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px; /* Mostly for vertical scrollbars */
  height: 10px; /* Mostly for horizontal scrollbars */
}
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background: #2869F0;
}
</style>