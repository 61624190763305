import { defineStore } from "pinia";
import { useAuthStore, useWalletStore, useConnectionStore, useOrderStore, useVerificationStore } from "@commons/store"

export const useGlobalStore = defineStore('global', {
  actions: {
    resetState() {
      useAuthStore().$reset();
      useWalletStore().$reset();
      useConnectionStore().$reset();
      useOrderStore().$reset();
      useVerificationStore().$reset();
    },
  },
});