// Import necessary dependencies
import { defineStore } from "pinia";
import axios from 'axios';

// Import global composable
import globalComposables from '@commons/composables/global';
const { getCookieValue } = globalComposables();

import { useAuthStore } from "@commons/store";

// Define the 'order' store module
export const useOrderStore = defineStore('order', {
  persist: true,
  state: () => {
    return {
      userLimits: [],
      feeList: [],
      prices: [],
    };
  },

  getters: {
    getUserLimits: (state) => {
      return state.userLimits;
    },
    getFeeList: (state) => {
        return state.feeList;
    },
    getPrice: (state) => (currencyShort, vsCurrencyShort, type) => {
        const matchingPrice = state.prices.find(p => p.currencyShort === currencyShort && p.vsCurrencyShort === vsCurrencyShort);
        let matchingInversedPrice = null; // Initialize the variable

        if (!matchingPrice) {
            matchingInversedPrice = state.prices.find(p => p.currencyShort === vsCurrencyShort && p.vsCurrencyShort === currencyShort);
        }

        if (matchingPrice && matchingPrice[type]) {
            return matchingPrice[type];
        } else if (matchingInversedPrice && matchingInversedPrice[type]) {
            return 1 / matchingInversedPrice[type];
        } else if (currencyShort === vsCurrencyShort) {
            return 1;
        } else {
            return 0;
        }
    },
  },
    
    actions: {
      getLimits() {
        const storeAuth = useAuthStore()
        return axios.get(process.env.VUE_APP_API_ORDER_URL + "user-limits", {
            withCredentials: true,
            headers: {
                'x-csrf-token': getCookieValue('X-CSRF-TOKEN') || storeAuth.getCSRFToken,
            },
        })
        .then((res) => {
            if (res && res.data && res.status === 200) {
                const limits = {
                    daily: res.data.daily.limitEur,
                    monthly: res.data.monthly.limitEur,
                };
                const remaining = {
                    daily: res.data.daily.availableEur,
                    monthly: res.data.monthly.availableEur,
                };
                this.setLimits({ limits: limits, remaining: remaining });
            }
        }).catch((error) => {
            console.log("Error fetching data:", error);
        });
      },
    createCourseLimit(payload) {
      const storeAuth = useAuthStore()
        return axios.post(
            process.env.VUE_APP_API_ORDER_URL + "user-limits",
            payload, {
                withCredentials: true,
                headers: {
                   'x-csrf-token': getCookieValue('X-CSRF-TOKEN') || storeAuth.getCSRFToken,
                },
            }
        ).catch((error) => {
            console.log("Error fetching data:", error);
        });
    },
      
      

    getAllFees() {
      const storeAuth = useAuthStore()
        return axios.get(process.env.VUE_APP_API_ORDER_URL + "service-fees", {
          withCredentials: true,
          headers: {
              'x-csrf-token': getCookieValue('X-CSRF-TOKEN') || storeAuth.getCSRFToken,
          },
        }).then((res) => {
          if (res && res.data && res.status === 200) {
            this.setFeeList(res.data);
            return this.feeList;
          }
            
          
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
        });

        
    },

    setLimits(payload) {
      this.userLimits = {
        limits: payload.limits,
        remaining: payload.remaining,
      };
    },
    
    setFeeList(payload) {
      this.feeList = payload;
    },
      
    setPrices(payload) {
        //
        // Only replace the updates
        //
        const index = this.prices.findIndex(object => {
            return (object.currencyShort === payload.currencyShort && object.vsCurrencyShort === payload.vsCurrencyShort) ||
            (object.currencyShort === payload.vsCurrencyShort && object.vsCurrencyShort === payload.currencyShort)
        })
        
        if (index === -1) {
          this.prices.push(payload)
        } else {
          this.prices[this.prices.findIndex(x => x.currencyShort === payload.currencyShort)] = payload
        }
    },
  },
});