// Import necessary dependencies
import { defineStore } from "pinia";
import router from '@/router';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const useAuthStore = defineStore('auth', {
  persist: true,
  state: () => {
    return {
      logged: false,
      sessionEndTime: null,
      refreshModalStatus: false,
      termsModalStatus: false,
      user: null,
      userState: null,
      accessToken: null,
      twoFactor: false,
    }
  },

  getters: {
    getSessionEndTime(state) {
      return state.sessionEndTime;
    },
    getUserData(state) {
      return state.user;
    },
    getUserId(state) {
      return state.user?.id
    },
    getCSRFToken(state) {
      return state.user ? state.user.csrfToken : null;
    },
    getAccessToken(state) {
      return state.accessToken;
    },
    getUserState(state) {
      return state.userState;
    },
    isLoggedIn(state) {
      return state.logged;
    },
    getTwoFactor(state) {
      return state.twoFactor;
    },
  },

  actions: {
    logout(payload) {
        this.logged = false;
        this.sessionEndTime = null;
        if (payload && 
            payload.redirect === true &&
            router.currentRoute.value.name !== "home"
        ) {
            router.push({ name: "home" });
        }
    },
    setLoginData(payload) {
      this.user = payload;
      this.logged = true;

        const endDate = dayjs().add(payload.logoutMs, "millisecond");
        console.log(endDate)
        this.sessionEndTime = endDate;

        setTimeout(() => {
            try {
              this.refreshModalStatus = true;
            } catch(err) {
                console.log(err);
            }
        }, payload.logoutMs * 0.9);
    },
    
    setAccessToken(payload) {
      this.accessToken = payload.accessToken;
    },
    refreshModalStatus(payload) {
      this.refreshModalStatus = payload;
    },
    termsModalStatus(payload) {
      this.termsModalStatus = payload;
    },
    setTwoFactor(payload) {
      this.twoFactor = payload;
    },
  },  
});