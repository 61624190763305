import { methodList, networks } from "@commons/components/data.js";

export default function globalCmp() {

    interface networkObject {
        explorerUrl: string;
        identifier: string;
    }

    const modalAction = (open: boolean) => {
        console.log(open)
        if(!open) {
            if(document.body.classList.contains("overflow-y-hidden")) {
                document.body.classList.remove("overflow-y-hidden")
            }
        } else {
            if(!document.body.classList.contains("overflow-y-hidden")) {
                document.body.classList.add("overflow-y-hidden")
            }
        }
    }

    const getCookieValue = (name) => (
        document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || null
    )

    const getExplorerLinksFromNetwork = (network: networkObject) => {
        if(network) {
            if(network.explorerUrl) {
                if(network.identifier === "iota_mainnet" || network.identifier === "iota_shimmer") {
                    const networkName = network.identifier === "iota_mainnet" ? "mainnet" : "shimmer"
                    return {
                        addr: network.explorerUrl + "/" + networkName + "/addr/",
                        tx: network.explorerUrl + "/" + networkName + "/message/",
                    };
                } else {
                    return {
                        addr: network.explorerUrl + "/address/",
                        tx: network.explorerUrl + "/tx/",
                    };
                }
            }
        }
    }

    const getNetworkFromPayMethod = (paymentMethod: string) => {
        if(paymentMethod && methodList.filter(m => m.id === paymentMethod)[0] && networks.filter((n) => n.id === (methodList.filter(m => m.id === paymentMethod)[0].networkId))[0].name) {
          return networks.filter((n) => n.id === (methodList.filter(m => m.id === paymentMethod)[0].networkId))[0];
        } else {
          return;
        }
    }

    const getMethodFromCurrency = (currency: string) => {
        // at the moment, always automatically use SEPA.
        if (currency === "EUR") {
          return methodList.filter(
            (x) => x.currency === currency && x.contractType === "connection"
          )[0];
        } else {
          return methodList.filter((x) => x.currency === currency)[0];
        }
    }

    const getNetworkFromCurrency = (currency: string) => {
        if(currency) {
            if(getMethodFromCurrency(currency).id && getNetworkFromPayMethod(getMethodFromCurrency(currency).id)) {
                return getNetworkFromPayMethod(getMethodFromCurrency(currency).id)
            } else {
                return;
            }
        }
    }

    const getNetworkIdFromNetworkIdentifier = (network: string) => {
        if(networks.filter((n) => n.identifier === network).length > 0) {
            return networks.filter((n) => n.identifier === network)[0].id;
        } else {
            return;
        }
    }


    return {
        modalAction,
        getExplorerLinksFromNetwork,
        getNetworkFromPayMethod,
        getMethodFromCurrency,
        getNetworkFromCurrency,
        getNetworkIdFromNetworkIdentifier,
        getCookieValue,
    }
}