// Import necessary dependencies
import { defineStore } from "pinia";


// Define the 'path' store module
export const usePathStore = defineStore('path', {
  persist: true,
  
  state: () => {
    return {
      nextPath: null,
      goForNext: false,
    };
  },

  getters: {
    getPath(state) {
      return state.nextPath;
    },
    getGoNext(state) {
      return state.goForNext;
    },
  },

  actions: {
    setNextPath(payload) {
      this.nextPath = payload;
      this.goForNext = true;
    },
    goForNext(payload) {
      this.goForNext = payload;
    },
  },
});
