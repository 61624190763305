// Import necessary dependencies
import { defineStore } from "pinia";
import { useOrderStore, useAuthStore } from "@commons/store";
import axios from 'axios';

// Import global composable
import globalComposables from '@commons/composables/global';
const { getCookieValue } = globalComposables();

// Define the 'verification' store module
export const useVerificationStore = defineStore('verification', {
  persist: true,
  
  state: () => {
    return {
      verificationStatus: {
        submission: Object,
        status: Object,
        riskLevel: NaN,
      },
      generalData: localStorage.getItem('generalData')
        ? JSON.parse(localStorage.getItem('generalData'))
        : null,
    }
  },

  getters: {
    getVerificationStatus(state) {
      return state.verificationStatus;
    },
    getRiskLevel(state) {
      return state.verificationStatus?.riskLevel;
    },
  },

  actions: {
    async getVerStatus(payload) {
      const storeAuth = useAuthStore()
      const storeOrder = useOrderStore()
      try {
        const response = await axios.get(
          process.env.VUE_APP_API_KYC_URL + 'verifications/?noCache=' + payload + '&includeSubmission=true',
          {
            withCredentials: true,
            headers: {
              'x-csrf-token': getCookieValue('X-CSRF-TOKEN') || storeAuth.getCSRFToken,
            },
          }
        );

        if (response && response.data && response.status === 200) {
          this.setVerificationStatus(response.data);

          // get fee list
          await storeOrder.getAllFees();

          // get user limits
          await storeOrder.getLimits();

          return response;
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    },
    setVerificationStatus(payload) {
      this.verificationStatus = payload;
    },
    setVerificationStatusSubmission(payload) {
      this.verificationStatus.submission[payload.key] = payload.value;
    },
    storeGeneralData(payload) {
      localStorage.setItem('generalData', JSON.stringify(payload));
      this.generalData = payload;
    },
  },
});
