import { currencies } from "@commons/components/data.js";

/*
Multilanguge keys: Placeholder + Internal Links
*/
export function markdown(value, placeholder) {
  if (!value) return "";
  value = value.toString();

  if (placeholder) {
    Object.keys(placeholder).forEach((key) => {
      value = value.replace(
        new RegExp("{(" + key + ")}", "g"),
        placeholder[key]
      );
    });
  }
  return value;
}

export function summary(value) {
  if (!value) return "";
  return value.replace(/<summary>/g, '<summary class="cursor-pointer text-black dark:text-white hover:opacity-80">').replace(/<details>/g, '<details class="py-12 border-b border-gray-200 dark:border-gray-800 dark:border-gray-700">');
}

export function progressBar(value) {
  if (!value) return "";

  const progReplacer = function(_, value0, value1) {
    return '<div class="py-2"><div class="flex gap-8 justify-between mb-1 text-sm items-center"><span>' + value1 + '</span><span>' + value0 + '%</span></div><div class="w-full border border-theme rounded-full h-2.5"><div class="h-2.5 bg-theme rounded-full" style="width: ' + value0 + '%"></div></div></div>';
  }

  return value.replace(/<progress-bar value="(.*?)">(.*?)<\/progress-bar>/g, progReplacer)
}

export function emoji(value) {
  if (!value) return "";
  return value.replace(/:P/g, "&#128540;").replace(/:\)/g, "&#128522;").replace(/:D/g, "&#128516;").replace(/:hm:/g, "&#129300;");
}

export function rating(value) {
  if (!value) return "";
  return value.replace(/\[A\]/g, '<span class="inline-flex my-1 mr-1 items-center justify-center flex-shrink-0 w-8 h-8 text-sm font-bold text-dayThemeText rounded-full" style="background:#2E6627">A</span>').replace(/\[B\]/g, '<span class="inline-flex my-1 mr-1 items-center justify-center flex-shrink-0 w-8 h-8 text-sm font-bold text-dayThemeText rounded-full bg-pos">B</span>').replace(/\[C\]/g, '<span class="inline-flex my-1 mr-1 items-center justify-center flex-shrink-0 w-8 h-8 text-sm font-bold text-dayThemeText rounded-full bg-neg">C</span>').replace(/\[D\]/g, '<span class="inline-flex my-1 mr-1 items-center justify-center flex-shrink-0 w-8 h-8 text-sm font-bold text-dayThemeText rounded-full" style="background:#932E23">D</span>')
}

export function idShortening(value) {
  if (!value) return "";
  return value.substring(24).toUpperCase();
}

export function ibanFormat(value) {
  if (!value) return "";
  return value.replace(/(.{4})/g, "$1 ");
}

export function addressFormat(value) {
  if (!value) return "";
  return value.substring(0, 4) + "(...)" + value.substring(value.length - 4);
}

export function findServiceUrl(networkId) {
  for (const currency of currencies) {
      const network = currency.networks.find(network => network.identifier === networkId);
      if (network) {
          return network.serviceUrl;
      }
  }
  return null; // Return null if no matching networkId is found
}

export function currencyFromNetwork(networkIdentifier) {
  return currencies.find(currency => 
    currency.networks.some(network => network.identifier === networkIdentifier)
)?.short;
}

export function clicksToRouter($event) {
  // ensure we use the link, in case the click has been received by a subelement
  let { target } = $event;
  while (target && target.tagName !== "A") target = target.parentNode;
  // handle only links that occur inside the component and do not reference external resources
  if (target && target.matches("a:not([href*='://'])") && target.href) {
    // if we want a class:  target.matches(".class123 a:not([href*='://'])")
    // some sanity checks taken from vue-router:
    const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } =
      $event;
    // don't handle with control keys
    if (metaKey || altKey || ctrlKey || shiftKey) return;
    // don't handle when preventDefault called
    if (defaultPrevented) return;
    // don't handle right clicks
    if (button !== undefined && button !== 0) return;
    //don't handle if `target="_blank"`
    if (target && target.getAttribute) {
      const linkTarget = target.getAttribute("target");
      if (/\b_blank\b/i.test(linkTarget)) return;
    }
    // don't handle same page links/anchors
    const url = new URL(target.href);
    const to = url.pathname.substring(url.pathname.lastIndexOf("/") + 1);

    if (window.location.pathname !== to && $event.preventDefault) {
      $event.preventDefault();

      if(!to.includes(".")) {
        // internal link withotu params, eg "home"
        this.$router.push({ name: to });
      } else {
        // internal link with params, eg. "terms.{ section:'general-terms' }"
        this.$router.push({ name: to.split(".")[0], params: { section: to.split(".")[1].toLowerCase(), sub: to.split(".")[2].toLowerCase() } });
      }
    }
  }
}

/*
seed: function (value) {
if (!value) return null;
return value.replace(/(.{3})/g,'<div class="w-33 fl">$1</div>').replace(/(.{90})/g, '<div class="clearfix">$1</div>');
},
*/
